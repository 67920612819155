import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import sidebarArrow from '../assets/img/sidebarArrow.png'
import { GraphicEq, Equalizer, PeopleAlt, Assessment, Translate, Explore, ExitToApp, AttachMoney, GroupWork, FindReplace, ChevronRight, ChevronLeft } from '@material-ui/icons';
import { Link } from "react-router-dom";
// import Logo from '../assets/img/logo.svg';
import Logo from '../assets/img/new-bleat-logo.png';

import AuthContext from '../store/AuthContext.js';
import UsersContext from '../store/UsersContext';
import SidebarContext from '../store/SidebarContext.js';



import '../App.scss';
import { East } from '@mui/icons-material';

const noSideBarRoutes = ["/login", "/register", "/builder"];

function SideBar() {

	const authCtx 								      = useContext(AuthContext);
	const usersCtx = useContext(UsersContext);
	const { pathname } 						      = useLocation();
	const navigate      								= useNavigate();

	// console.log(pathname);
	
	const isLoggedIn 							      = authCtx.isLoggedIn;

	const [isLoading, setIsLoading]     = useState(true);
  const [assessments, setAssessments] = useState([]);
	const [hideActive, setHideActive]   = useState("");
	const [currentAssessment, setCurrentAssessment] = useState(null);
	const [currentChildAssessment, setCurrentChildAssessment] = useState(null);
	const sidebarCtx = useContext(SidebarContext);
	const [isHidden, setIsHidden] = useState(false);
	const [versionChildName, setVersionChildName] = useState('');

	useEffect(() => {
    setIsLoading(true);
    getAssessments();
  }, []);

	useEffect(() => {
		if(pathname) {
			let path = pathname.split("/");
			// console.log(path);
			if ((path[1] === "assessments" || path[1] === "assessmentsv2" || path[1] === 'assessmentsparishinsights' ||  path[1] === 'assessmentschurchinsights') && path[2]) {
				assessments.forEach(assessment => {
					if (assessment.childrenAssessments.length > 0) {
						assessment.childrenAssessments.forEach(item => {
							if (item?.id.toString() === path[2]?.toString()) {
								setCurrentChildAssessment(item.id);
								setCurrentAssessment(assessment.id);
								setHideActive("")
							}
						}) 
					}
					if (assessment?.id?.toString() === path[2]?.toString()) {
						setCurrentChildAssessment(null)
						setCurrentAssessment(assessment.id);
						setHideActive("")
					}
				})
			} 
      else setHideActive(" hide-active");
		}

	}, [assessments, pathname]);

  const getAssessments = () => {
    fetch(process.env.REACT_APP_API_URI + "/v4/admin/assessment/byAccount/" + authCtx.accountId, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
      setIsLoading(false);
      setAssessments(data.data);
    });
  }
	
	if (noSideBarRoutes.some((item) => pathname.includes(item))) return null;

	return (
		<div className={sidebarCtx.isOpen ? 'menu close-sidebar' : 'menu'}>
			<div className="logo-arrow-container" style={{flexDirection:sidebarCtx.isOpen? "column-reverse" : "" }}>
				<div className={ sidebarCtx.isOpen ? "company-logo company-logo-closed" : "company-logo"}>
					<img src={Logo} alt="company logo" />
				</div>
				<div className="sidebar-arrow" >
					{
						sidebarCtx.isOpen ? <div className='right-arrow-logo' onClick={()=>{ 
							sidebarCtx.toggleSidebar();
							if ( isHidden ) { setIsHidden(false) }
							else { setTimeout(() => { setIsHidden(true); }, 400); }
						}}> <ChevronRight/> </div> : <div className="left-arrow-logo" onClick={()=>{
							sidebarCtx.toggleSidebar()
							if ( isHidden ) { setIsHidden(false) }
							else { setTimeout(() => { setIsHidden(true); }, 400); }
						}}><ChevronLeft/></div>
					}
				</div>
			</div>
			<nav>
				{/* {isLoggedIn && !sidebarCtx.isOpen ? (<h3 className="welcome-user">Welcome,<br/>{authCtx?.userName?.split(" ")[0]}!</h3>) : <></>} */}
				
				{/* 	<h4 className="assessments-header">Assessments:</h4> */}
				<div className={ authCtx.accountId == 0 ? "assessments-list" : "assessments-list account" }>
					<ul>
						{
							assessments?.map((assessment, index) => {
								let activeClass = "";
								let childrenClass = "";
								let replace = /Church Management Software/gi;
								let assessmentNameFull = assessment?.title.toString();
								let assessmentName = assessment?.title.toString().replace(replace, "ChMS").trim();
								assessmentName = assessmentName.length > 24 ? assessmentName.substring(0, 24) + "..." : assessmentName;
								if(assessment?.id?.toString() === currentAssessment?.toString()) {
									activeClass = " active-assessment ";
									childrenClass = "assessment-dropdown-active"
								} else {
									activeClass = "";
									childrenClass = "assessment-dropdown-hide"
								}
								
								return (
									<>
								{ assessment?.boVersion === 'v1-v2' || assessment?.boVersion === 'v1-v2' || assessment?.id === 74 ? assessmentBOV1V2Item(assessment, assessmentNameFull, activeClass, index, assessmentName)
									 : <li title={assessmentNameFull} style={isHidden ? { display: "flex", justifyContent: "center" } : {}} key={assessment?.id} onClick={() => navigate("/assessments/" + assessment?.id, { replace: true })}>

												<div className={"assessment-item" + activeClass + hideActive}>
													<span className="assessment-num" style={{ marginRight: isHidden ? "0" : "" }}>{index + 1} </span>
													{isHidden ? <></> : <span className={`fade-out ${sidebarCtx.isOpen ? 'fade-in' : ''}`}> {assessmentName}</span>}
												</div>

												<div className={childrenClass}>
													<ul style={assessment?.childrenAssessments?.length === 0 ? { display: "none" } : {}}>
														<li onClick={() => { navigate("/assessments/" + assessment?.id, { replace: true }) }} style={(currentAssessment?.toString() === assessment?.id.toString() && currentChildAssessment === null) ? { padding: '0 0 7px 0', fontWeight: 'bold' } : { padding: '0 0 7px 0' }}>All Assessments</li>
														{assessment?.childrenAssessments?.map((item, index) => <li style={(currentChildAssessment?.toString() === item?.id?.toString()) ? { padding: '7px 0', fontWeight: 'bold' } : { padding: '7px 0' }} key={index}
															onClick={(e) => {
																e.stopPropagation();
																if (item.assessment_type === "simple") {
																	navigate("/assessmentsv2/" + item?.id, { replace: true });
																} else navigate("/assessments/" + item?.id, { replace: true });
															}}>{item.title}</li>)}
													</ul>
												</div>
											</li>
										}
									</>
								)
							})
						}
					</ul>					
				</div>

				<div className="bottom-links" style={ sidebarCtx.isOpen ? {left:"1%"} : {} }>
					<div className="links-container">
						{ 
							authCtx.accountId == 0 ? <>
								<Link to="/overview" className="nav-link"><Equalizer />&nbsp;&nbsp; { isHidden ? <></> : <span className={`fade-out ${sidebarCtx.isOpen ? 'fade-in' : ''}`}> Overview </span> } </Link>
								<Link to="/clients" className="nav-link"><PeopleAlt />&nbsp;&nbsp; { isHidden ? <></> : <span className={`fade-out ${sidebarCtx.isOpen ? 'fade-in' : ''}`}> Clients </span> } </Link>
								<Link to="/assessments" className="nav-link"><Assessment />&nbsp;&nbsp; { isHidden ? <></> : <span className={`fade-out ${sidebarCtx.isOpen ? 'fade-in' : ''}`}> Assessments </span> } </Link>
								<Link to="/engagement" className="nav-link"><GraphicEq />&nbsp;&nbsp; { isHidden ? <></> : <span className={`fade-out ${sidebarCtx.isOpen ? 'fade-in' : ''}`}> Landing Pages </span> } </Link>
								<Link to="/datamining" className="nav-link"><FindReplace />&nbsp;&nbsp; { isHidden ? <></> : <span className={`fade-out ${sidebarCtx.isOpen ? 'fade-in' : ''}`}> Data Mining </span> } </Link>
							</> : null
						}
						{/* <a className="logout" onClick={() => authCtx.logout()}><div className="lower-icon"><ExitToApp style={{color: "#212A42", fontSize: "18px"}} /></div> Log out</a> */}
						<a className="logout" onClick={() => authCtx.logout()}><ExitToApp />&nbsp;&nbsp;{ isHidden ? <></> : <span style={{ opacity : sidebarCtx.isOpen ? "0" : "1" , transition : '1s' }} >Log Out</span>}</a>
					</div>
				</div>
			</nav>
			
		</div>
	);

	function assessmentBOV1V2Item(assessment, assessmentNameFull, activeClass, index, assessmentName){
		switch (assessment?.id) {			
		
			case 50:
				return assessmentBOV1V2ItemRender(assessment, assessmentNameFull, 50, activeClass, index, [assessmentName, 'Parish Insights'], [ '/assessmentsv2/', '/assessmentsparishinsights/' ]);

			case 71:
				return assessmentBOV1V2ItemRender(assessment, assessmentNameFull, 71, activeClass, index, [assessmentName, 'Backoffice v2'], [ '/assessments/', '/assessmentsv2/' ]);

			case 73:
				return assessmentBOV1V2ItemRender(assessment, assessmentNameFull, 73, activeClass, index, [assessmentName, 'Backoffice v2'], [ '/assessments/', '/assessmentsv2/' ]);
      
      case 74:
        return assessmentBOV1V2ItemRender(assessment, assessmentNameFull, 74, activeClass, index, [assessmentName, 'Church Insights'], [ '/assessmentsv2/', '/assessmentschurchinsights/' ]);

			case 84:
        return assessmentBOV1V2ItemRender(assessment, assessmentNameFull, 74, activeClass, index, [assessmentName, 'Backoffice v2'], [ '/assessments/', '/assessmentsv2/' ]);

			case 85:
        return assessmentBOV1V2ItemRender(assessment, assessmentNameFull, 74, activeClass, index, [assessmentName, 'Backoffice v2'], [ '/assessments/', '/assessmentsv2/' ]);

			default:
				break;
		}
	}

	function assessmentBOV1V2ItemRender(assessment, assessmentNameFull, itemId, activeClass, index, assessmentNamesList, navigationLinkList) {
		return <>
			<div style={currentAssessment === itemId ? { backgroundColor: '#0F131D' } : {}}>
				<li title={assessmentNameFull} style={isHidden ? { display: "flex", justifyContent: "center" } : {}} key={assessment?.id} onClick={() => { setVersionChildName(''); navigate(`${navigationLinkList[0]}` + assessment?.id, { replace: true }); } }>
					<div className={"assessment-item" + (versionChildName === '' ? activeClass : '') + hideActive}>
						<span className="assessment-num" style={{ marginRight: isHidden ? "0" : "" }}>{index + 1} </span>
						{isHidden ? <></> : <span className={`fade-out ${sidebarCtx.isOpen ? 'fade-in' : ''}`}> {assessmentNamesList[0]}</span>}
					</div>
					<div className="assessment-dropdown-active">
						<ul style={{ display: "block" }}>
						{ assessmentNamesList.slice(1).map((item, idx) => <li key={idx} title='' onClick={(e) => { e.stopPropagation(); setVersionChildName(item); navigate(`${navigationLinkList[idx+1]}` + assessment?.id, { replace: true }); } } style={versionChildName === item && currentAssessment === itemId ? { fontWeight: 'bold', color: '#F2D466' } : {}}>{item}</li> )}
						</ul>
					</div>
				</li>
			</div>
		</>;
	}
}
export default SideBar;